import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`RV Coefficient`}</h1>
    <ul>
      <li parentName="ul">{`The RV-coefficient is a generalization of Pearson's R-value to multivariate (multi-dimensional, or vector valued) comparisons`}</li>
      <li parentName="ul">{`For more see: `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/RV_coefficient"
        }}>{`https://en.wikipedia.org/wiki/RV_coefficient`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      